@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:400,600,700,800,900&display=swap);
body {
  margin: 0;
  font-family: 'Nunito Sans', Futura, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}


#root:nth-last-child(2) {
  padding-bottom: 40px !important;
}

span {
  overflow-x: hidden;
}

.drift-conductor-item.drift-frame--mobile {
  position: static;
  position: initial;
}

